import React from "react";
import avatar from "../assets/avatar.jpg";
import { MdOutlineKeyboardArrowRight} from "react-icons/md";
import {Link} from 'react-scroll';

const Home = () => {
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-gray-800 to-black"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row text-white ">
        <div className="flex flex-col justify-center h-full ">
          <p className="text-gray-500 py-1 max-w-md italic mx-10 mt-10">
            Hi there 👋 <br /> I'm a Software Engineer with work experience in
            designing, developing, implementing and delivering complex
            applications and solutions using a range of technologies and
            programming languages. <br /> Don't forget to check out my portfolio
            &#128522;
            <br />
            Cheers
          </p>
          <div>
            <Link to="portfolio" smooth duration={500}
              className="group text-white w-fit px-2 py-1  flex items-center rounded-md
            bg-gradient-to-r from-cyan-400 to-blue-700 m-10 hover:cursor-pointer"
            >
              Portfolio
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>
        <div className="">
          <img
            src={avatar}
            alt="avatar"
            className=" w-1/2 md:w-2/3 w-50 h-50  rounded-full ring-1 ring-gray-300 dark:ring-gray-500 mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
