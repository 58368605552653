import React from "react";
import jokeGenerator from "../assets/joke-generator.jpg";
import lotr from "../assets/lotr.jpg";
import movieApp from "../assets/movie-app.jpg";
import quoteGenerator from "../assets/quote-generator.jpg";
import telegramBot from "../assets/telegram-bot.jpg";
import weatherApp from "../assets/weather-app.jpg";

const Portfolio = () => {
  const portfolio = [
    {
      id: 3,
      name: "Joke Generator",
      src: jokeGenerator,
      code: "https://github.com/AlehKhvasko/joke-generator",
    },
    {
      id: 4,
      name: "Lord of the Rings",
      src: lotr,
      code: "https://github.com/AlehKhvasko/LOTR",
    },
    {
      id: 5,
      name: "Movie App",
      src: movieApp,
      code: "https://github.com/AlehKhvasko/movie-app",
    },
    {
      id: 6,
      name: "Quote Generator",
      src: quoteGenerator,
      code: "https://github.com/AlehKhvasko/quote-generator",
    },
    {
      id: 7,
      name: "Telegram Bot",
      src: telegramBot,
      code: "https://github.com/AlehKhvasko/telegram-bot-planning",
    },
    {
      id: 8,
      name: "Weather App",
      src: weatherApp,
      code: "https://github.com/AlehKhvasko/weather-app",
    },
  ];
  return (
    <div
      name="portfolio"
      className="bg-gradient-to-b from-gray-700 to-black  w-full
    text-white md:h-screen"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold italic mx-10">Portfolio</p>
        </div>
        <div className="grid sm:cols-2 md:grid-cols-3 gap-8 px-8 sm:px-8 m-auto">
          {portfolio.map(({ id, src, name, code }) => (
            <div
              key={id}
              className="shadow-md shadow-gray-600  hover:shadow-white  "
            >
              <div className="text-center italic text-xl">{name}</div>
              <img
                src={src}
                alt=""
                className="object-fill duration-200 h-2/3 image-responsive"
              />
              <div className="flex items-center justify-center">
                <a
                  href={code}
                  className="w-1/2 px-6 py-3 duration-200 italic bg-gray-800 shadow-sm shadow-gray-400 hover:scale-105 hover:bg-gray-600"
                >
                  <button >
                    Demo
                  </button>
                </a>
                <a
                  href={code}
                  className="w-1/2 px-6 py-3 duration-200 italic bg-gray-800 shadow-sm shadow-gray-400 hover:scale-105 hover:bg-gray-600"
                >
                  <button>Code</button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
