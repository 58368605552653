import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-black to to-gray-700 text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="italic text-4xl font-bold mx-10">About</p>
        </div>
        <p className="italic mx-10">
          Unconventional engineer with a proven track record of delivering out
          of the box solutions. A curious problem solver with a strong
          understanding of object-oriented programming, debugging and developing
          in both statically-typed, and dynamically-typed programming languages.
          I enjoy solving problems daily and love brightening other people's
          days.
        </p>
      </div>
    </div>
  );
};

export default About;
